<template>
  <div class="row" style="max-width: calc(100% - 30px);">
    <div v-if="model" class="col-12 q-mx-md">
      <form-builder :schema="schema" />
    </div>

    <div v-if="phoneModel" class="col-12 q-mx-md">
      <form-builder :schema="phoneSchema" />
    </div>

    <div v-if="addressModel" class="col-12 q-mx-md">
      <form-builder :schema="addressSchema" />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['phone', 'profile', 'address'],
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    profile: {
      type: Object,
      default () {
        return {}
      }
    },
    phone: {
      type: Object,
      default () {
        return {}
      }
    },
    address: {
      type: Object,
      default () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    phoneType: {
      type: String,
      default () {
        return 'tel'
      }
    },
    appOptions: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      model: null,
      phoneModel: null,
      addressModel: null,
    }
  },
  mounted () {
    // Assign props to models
    this.model = this.profile;
    this.phoneModel = this.phone;
    this.addressModel = this.address;
  },
  methods: {
    loadLocalitiesByPostcode (value) {
      if (value && value.length < 4) {
        this.addWarningNotification('Postcode too short')
        return
      }

      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { field: 'extId', type: 'eq', value }
        ]
      }

      return this.$service.postcode.getAll(query)
          .then(({ items }) => {
            if (items.length === 0) {
              this.addWarningNotification('No localities found!')
              return
            }

            this.localities = items.reduce((acc, item) => {
              const locality = item._embedded.locality
              if (!acc.find(x => x.id === locality.id)) {
                acc.push(locality)
              }

              return acc
            }, [])

            if (this.localities.length === 1) {
              return this.localities[0]
            }
          })
    },
  },
  computed: {
    schema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                disabled: this.disabled,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                onChange: (name) => {
                  this.$emit('profile', { ...this.model, name })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'surname',
                value: this.model.surname,
                disabled: this.disabled,
                label: this.$t('Second name'),
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                onChange: (surname) => {
                  this.$emit('profile', { ...this.model, surname })
                }
              },
              {
                type: 'input',
                inputType: 'email',
                field: 'email',
                value: this.model.email,
                required: false,
                disabled: this.disabled,
                label: this.$t('E-mail'),
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                onChange: (email) => {
                  this.$emit('profile', { ...this.model, email })
                }
              }
            ]
          }
        ]
      }
    },
    phoneSchema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: this.phoneType,
                maxlength: 255,
                field: 'phone',
                value: this.phoneModel.phone,
                disabled: this.disabled,
                label: this.$t('Phone'),
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                styleClasses: 'custom-label-border-tel',
                onChange: phone => {
                  this.$emit('phone', { ...this.phoneModel, phone })
                }
              }
            ]
          }
        ]
      }
    },
    addressSchema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                isNotNumber: true,
                value: this.addressModel.postcode,
                field: 'postcode',
                required: !this.notRequired,
                debounce: 300,
                disabled: this.disabled,
                label: this.$t('ZIP/Postal code'),
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                onInput: postcode => {
                  this.loadLocalitiesByPostcode(postcode).then(locality => {
                    this.addressModel._embedded.locality = locality;
                    console.log(this.addressModel._embedded);

                    this.$emit('address', { ...this.addressModel, postcode })
                  })

                  this.$emit('address', { ...this.addressModel, postcode })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('City'),
                value: this.addressModel._embedded?.locality,
                disabled: this.disabled,
                field: 'locality',
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                required: !this.notRequired,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    let name = row.name

                    if (row._embedded?.area?.name) {
                      name += ', ' + row._embedded?.area?.name
                    }

                    if (row._embedded?.country?.name) {
                      name += ', ' + row._embedded.country.name
                    }

                    return name
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: locality => {
                  this.$emit('address', { ...this.addressModel, locality })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'street',
                value: this.addressModel.street,
                disabled: this.disabled,
                required: !this.notRequired,
                label: this.$t('Street'),
                wrapperStyleClasses: 'col-12 q-py-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                onChange: street => {
                  this.$emit('address', { ...this.addressModel, street })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('House'),
                field: 'house',
                value: this.addressModel.house,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-4 q-py-xs q-pr-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                onChange: house => {
                  this.$emit('address', { ...this.addressModel, house })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'quarter',
                disabled: this.disabled,
                label: this.$t('Quarter'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                value: this.addressModel.quarter,
                onChange: quarter => {
                  this.$emit('address', { ...this.addressModel, quarter })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'block',
                disabled: this.disabled,
                label: this.$t('Block'),
                wrapperStyleClasses: 'col-4 q-py-xs q-pl-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                value: this.addressModel.block,
                onChange: block => {
                  this.$emit('address', { ...this.addressModel, block })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'entrance',
                disabled: this.disabled,
                label: this.$t('Entrance'),
                wrapperStyleClasses: 'col-4 q-py-xs q-pr-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                value: this.addressModel.entrance,
                onChange: entrance => {
                  this.$emit('address', { ...this.addressModel, entrance })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'floor',
                disabled: this.disabled,
                label: this.$t('Floor'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                value: this.addressModel.floor,
                onChange: floor => {
                  this.$emit('address', { ...this.addressModel, floor })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'apartment',
                disabled: this.disabled,
                label: this.$t('Apartment'),
                wrapperStyleClasses: 'col-4 q-py-xs q-pl-xs',
                noFilled: true,
                outlined: true,
                stackLabel: true,
                styleClasses: 'custom-label-border',
                value: this.addressModel.apartment,
                onChange: apartment => {
                  this.$emit('address', { ...this.addressModel, apartment })
                }
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
